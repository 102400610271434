<template>
  <p class="clearfix mb-0">
    <AppVersion />
    <span class="float-md-right d-none d-md-block">Made in Bavaria 🥨 by FastRocket</span>
  </p>
</template>

<script>
import AppVersion from './AppVersion.vue'

export default {
  components: {
    AppVersion,
  },
}
</script>
