<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <b-row v-if="$route.meta.pageTitle" class="content-header">
          <b-col class="content-header-left mb-2" cols="12" md="9">
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h2 v-if="$route.name !== 'home'" class="float-left pr-1 mb-0">
                  {{ $route.meta.pageTitle }}
                </h2>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </slot>
      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition,
      contentWidth,
    }
  },
}
</script>

<style></style>
