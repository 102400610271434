import { Action, Subjects } from '@/libs/acl/ability'

export default [
  {
    title: 'Management.Management',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Management.Users.Users',
        route: 'users',
        icon: 'UserIcon',
        action: Action.Read,
        resource: Subjects.Users,
      },
      {
        title: 'Management.Employees.Employees',
        route: 'employees',
        icon: 'UsersIcon',
        action: Action.Read,
        resource: Subjects.Employees,
      },
      {
        title: 'Absences.Absences',
        route: 'absences',
        icon: 'CalendarIcon',
        action: Action.Read,
        resource: Subjects.Absences,
      },
      {
        title: 'Management.Layers.Layers',
        route: 'layers',
        icon: 'LayersIcon',
        action: Action.Read,
        resource: Subjects.Layers,
      },
      {
        title: 'Management.Orders.MultipleOrders',
        route: 'orders',
        icon: 'TruckIcon',
        action: Action.Read,
        resource: Subjects.Orders,
      },
      {
        title: 'Management.ServiceProviders.ServiceProviders',
        route: 'service-provider',
        icon: 'ShoppingCartIcon',
        action: Action.Read,
        resource: Subjects.ServiceProvider,
      },
    ],
  },
  {
    title: 'NotificationOfNeed.NotificationOfNeed',
    route: 'notification-of-need',
    icon: 'FileTextIcon',
    action: Action.Read,
    resource: Subjects.NotificationOfNeed,
  },
  {
    title: 'StaffScheduling.StaffScheduling',
    route: 'staff-scheduling',
    icon: 'GitPullRequestIcon',
    action: Action.Read,
    resource: Subjects.StaffScheduling,
  },
  {
    title: 'ManageShifts.ManageShifts',
    route: 'manage-shifts',
    icon: 'ClockIcon',
    action: Action.Read,
    resource: Subjects.ManageShifts,
  },
  {
    title: 'Evaluations.Evaluations',
    route: 'evaluations',
    icon: 'ActivityIcon',
    children: [
      {
        title: 'Evaluations.TotalCost.TotalCost',
        route: 'total-cost',
        icon: 'DollarSignIcon',
        action: Action.Read,
        resource: Subjects.TotalCost,
      },
      {
        title: 'Evaluations.InternalShifts.InternalShifts',
        route: 'internal-shifts',
        icon: 'BarChart2Icon',
        action: Action.Read,
        resource: Subjects.InternalShifts,
      },
    ],
  },
]
