<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template v-if="user" #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.firstname }} {{ user.lastname }}
            </p>
            <span class="user-status">{{ $t(`Auth.Roles.${user.role}`) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :text="user.firstname.charAt(0) + user.lastname.charAt(0)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t('Auth.Login.Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import { logout } from '@/auth/utils'

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BLink,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  methods: {
    logout() {
      logout()
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
